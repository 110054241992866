const safeJsonParse = (json: string | null) => {
  try {
    return json ? JSON.parse(json) : [];
  } catch (e) {
    console.error("Error parsing JSON from localStorage:", e);
    return [];
  }
};

export default safeJsonParse;
