"use client";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventHandler } from "../EventHandler";
import { trackPageView } from "@snowplow/browser-tracker";
import safeJsonParse from "common-toolbox/src/helpers/utils/safeJsonParse";
var SnowplowHandler = /** @class */ (function (_super) {
    __extends(SnowplowHandler, _super);
    function SnowplowHandler() {
        return _super.call(this, "snowplow", []) || this;
    }
    SnowplowHandler.prototype.canProcess = function () {
        return true;
    };
    SnowplowHandler.prototype.addCommonContext = function () {
        var storedExperiments = safeJsonParse(localStorage.getItem("ab-experiments"));
        var formattedExperiments = storedExperiments.reduce(function (acc, exp) {
            acc["experiment_".concat(exp.id)] = exp.assignedVariant;
            return acc;
        }, {});
        return {
            schema: "iglu:com.withlocals.storefront/common_data/jsonschema/1-0-0",
            data: __assign({ application: "storefront" }, formattedExperiments),
        };
    };
    SnowplowHandler.prototype.process = function (event) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        switch (event.name) {
            case "page_view":
                return trackPageView({
                    context: [
                        {
                            schema: "iglu:com.withlocals.storefront/page_view/jsonschema/1-0-0",
                            data: {
                                custom_event_type: "page_view",
                                id: (_a = event.params) === null || _a === void 0 ? void 0 : _a._userId,
                                email: (_b = event.params) === null || _b === void 0 ? void 0 : _b._userEmail,
                            },
                        },
                        this.addCommonContext(),
                    ],
                });
            case "experience_viewed":
                return trackPageView({
                    context: [
                        {
                            schema: "iglu:com.withlocals.storefront/experience_viewed/jsonschema/1-0-0",
                            data: {
                                custom_event_type: "experience_viewed",
                                experience_id: (_c = event.params) === null || _c === void 0 ? void 0 : _c.experience_id,
                                country: (_d = event.params) === null || _d === void 0 ? void 0 : _d.country,
                                area: (_e = event.params) === null || _e === void 0 ? void 0 : _e.area,
                            },
                        },
                        this.addCommonContext(),
                    ],
                });
            case "consent":
                return trackPageView({
                    context: [
                        {
                            schema: "iglu:com.withlocals.storefront/consent/jsonschema/1-0-0",
                            data: {
                                custom_event_type: "consent",
                                advertisement: (_f = event.params) === null || _f === void 0 ? void 0 : _f.advertisement,
                                personalised_ads: (_g = event.params) === null || _g === void 0 ? void 0 : _g.personalised_ads,
                                analytics: (_h = event.params) === null || _h === void 0 ? void 0 : _h.analytics,
                            },
                        },
                        this.addCommonContext(),
                    ],
                });
            default:
                console.error("[handler-".concat(this.id, "]"), "unknown event:", event);
        }
    };
    return SnowplowHandler;
}(EventHandler));
export { SnowplowHandler };
